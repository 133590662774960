define("discourse/plugins/discourse-knowledge-base/discourse/templates/components/knowledge-base-title", ["exports", "ember-this-fallback/deprecations-helper", "@ember/template-factory"], function (_exports, _deprecationsHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <span {{action 'goHome'}}>{{siteSettings.knowledge_base_title}}</span>
  
  */
  {
    "id": "hj9Bgjs8",
    "block": "[[[11,1],[4,[38,0],[[30,0],\"goHome\"],null],[12],[1,[30,0,[\"siteSettings\",\"knowledge_base_title\"]]],[13],[1,\"\\n\"],[1,[28,[32,0],[\"[[\\\"The `siteSettings` property path was used in the `discourse/plugins/discourse-knowledge-base/discourse/templates/components/knowledge-base-title.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.siteSettings}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]]\"],null]]],[],false,[\"action\"]]",
    "moduleName": "discourse/plugins/discourse-knowledge-base/discourse/templates/components/knowledge-base-title.hbs",
    "scope": () => [_deprecationsHelper.default],
    "isStrictMode": false
  });
});