define("discourse/plugins/discourse-knowledge-base/discourse/components/knowledge-base-topic-list-item", ["exports", "discourse-common/utils/decorators", "discourse/lib/url"], function (_exports, _decorators, _url) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend(dt7948.p({
    tagName: 'li',
    classNameBindings: [':knowledge-base-topic-list-item', 'active'],
    title: Ember.computed.alias('topic.title'),
    active(topicId, currentItemId) {
      return topicId == currentItemId;
    },
    click() {
      const slug = this.get('category.fullSlug');
      const topic = this.get('topic');
      this.toggleMenu();
      _url.default.routeTo('/k' + '/' + slug + '/' + topic.slug + '/' + topic.id);
    }
  }, [["method", "active", [(0, _decorators.default)('topic.id', 'currentItemId')]]]));
});