define("discourse/plugins/discourse-knowledge-base/discourse/components/knowledge-base-topic-list", ["exports", "discourse-common/utils/decorators", "discourse/lib/ajax", "discourse/lib/ajax-error", "discourse/lib/url", "discourse/models/category"], function (_exports, _decorators, _ajax, _ajaxError, _url, _category) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend(dt7948.p({
    classNames: 'knowledge-base-topic-list',
    visible: Discourse.SiteSettings.knowledge_base_expanded_nav,
    didInsertElement() {
      this.set('sortedTopics', this.flagFirstAndLast(this.get('topics')));
    },
    setVisibleIfCurrent() {
      const topics = this.get('topics');
      const category = this.get('category');
      const currentItemId = this.get('currentItemId');
      if (topics.find(t => t.id == currentItemId) || category.topic_id == currentItemId) {
        this.set('visible', true);
      }
    },
    categoryClass(categoryTopicId, currentItemId) {
      let categoryClass = 'title';
      if (currentItemId == categoryTopicId) {
        categoryClass += ' active';
      }
      return categoryClass;
    },
    categoryUrl(category) {
      return Discourse.getURL(`/k/${_category.default.slugFor(category, '_')}`);
    },
    topicListClasses(visible) {
      let classes = 'topics';
      if (visible) classes += ' visible';
      return classes;
    },
    flagFirstAndLast(topics) {
      topics.forEach((t, i) => {
        if (i == 0) {
          Ember.set(t, 'isFirst', true);
          Ember.set(t, 'isLast', false);
        } else if (i == topics.length - 1) {
          Ember.set(t, 'isLast', true);
          Ember.set(t, 'isFirst', false);
        } else {
          Ember.set(t, 'isFirst', false);
          Ember.set(t, 'isLast', false);
        }
      });
      return topics;
    },
    sort(topicId, sort) {
      let sortedTopics = this.get('sortedTopics');
      let topic = sortedTopics.findBy('id', topicId);
      let index = sortedTopics.indexOf(topic);
      let newIndex;
      switch (sort) {
        case 'up':
          newIndex = index - 1;
          break;
        case 'down':
          newIndex = index + 1;
          break;
        case 'top':
          newIndex = 0;
          break;
        case 'bottom':
          newIndex = sortedTopics.length - 1;
          break;
        default:
          newIndex = index;
      }
      sortedTopics.splice(newIndex, 0, sortedTopics.splice(index, 1)[0]);
      sortedTopics = this.flagFirstAndLast(sortedTopics);
      this.set('sortedTopics', sortedTopics);
      this.notifyPropertyChange('sortedTopics');
    },
    showSortToggle(showSortControls, isAdmin) {
      return !showSortControls && isAdmin;
    },
    toggleIcon(visible) {
      return visible ? 'chevron-down' : 'chevron-right';
    },
    saveSort() {
      const category = this.get('category');
      const topics = this.get('sortedTopics');
      this.set('saving', true);
      (0, _ajax.ajax)(`/k/${category.slug}/sort`, {
        type: 'PUT',
        data: {
          sorted_ids: topics.map(t => t.id)
        }
      }).then(result => {
        if (!result.success) {
          this.set('sortedTopics', this.get('topics'));
        }
        this.set('showSortControls', false);
      }).catch(_ajaxError.popupAjaxError).finally(() => this.set('saving', false));
    },
    actions: {
      toggleList() {
        this.toggleProperty('visible');
      },
      toggleSortControls() {
        this.toggleProperty('showSortControls');
      },
      sort(opts) {
        this.sort(opts.topicId, opts.sort);
      },
      saveSort() {
        this.saveSort();
      },
      goToCategory() {
        this.toggleMenu();
        _url.default.routeTo(this.get('categoryUrl'));
      }
    }
  }, [["method", "setVisibleIfCurrent", [(0, _decorators.observes)('currentItemId')]], ["method", "categoryClass", [(0, _decorators.default)('category.topic_id', 'currentItemId')]], ["method", "categoryUrl", [(0, _decorators.default)('category')]], ["method", "topicListClasses", [(0, _decorators.default)('visible')]], ["method", "showSortToggle", [(0, _decorators.default)('showSortControls', 'currentUser.admin')]], ["method", "toggleIcon", [(0, _decorators.default)('visible')]]]));
});