define("discourse/plugins/discourse-knowledge-base/discourse/components/knowledge-base-title", ["exports", "discourse/lib/url", "discourse-common/lib/get-owner"], function (_exports, _url, _getOwner) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    classNames: 'knowledge-base-title',
    actions: {
      goHome() {
        const controller = (0, _getOwner.getOwner)(this).lookup('controller:knowledge-base');
        controller.set('currentItemId', null);
        this.toggleMenu();
        _url.default.routeTo('/k');
      }
    }
  });
});