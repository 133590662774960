define("discourse/plugins/discourse-knowledge-base/knowledge-base-route-map", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;
  function _default() {
    this.route('knowledge', {
      path: '/knowledge'
    });
    this.route('knowledgeBase', {
      path: '/k'
    }, function () {
      this.route('section', {
        path: '/:slug'
      });
      this.route('topic', {
        path: '/:slug/:title/:topic_id'
      });
    });
  }
});