define("discourse/plugins/discourse-knowledge-base/discourse/components/knowledge-base-nav", ["exports", "discourse-common/utils/decorators", "discourse/models/category", "discourse/lib/url"], function (_exports, _decorators, _category, _url) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend(dt7948.p({
    classNameBindings: [':knowledge-base-nav', 'expanded'],
    didInsertElement() {
      Ember.$(document).on('click', Ember.run.bind(this, this.outsideClick));
    },
    willDestroyElement() {
      Ember.$(document).off('click', Ember.run.bind(this, this.outsideClick));
    },
    outsideClick(e) {
      if (this.site.mobileView && !this.isDestroying && !$(e.target).closest('.knowledge-base-nav').length) {
        this.set('expanded', false);
      }
    },
    navList(topicsList) {
      const categories = _category.default.list().filter(c => c.knowledge_base);
      return Object.keys(topicsList).map(categoryId => {
        let category = categories.find(c => c.id.toString() === categoryId.toString());
        let topics = topicsList[categoryId].filter(t => t.id !== category.topic_id);
        return {
          category,
          topics
        };
      });
    }
  }, [["method", "navList", [(0, _decorators.default)('topicsList')]]]));
});