define("discourse/plugins/discourse-knowledge-base/discourse/controllers/knowledge-base", ["exports", "discourse-common/utils/decorators", "@ember/service"], function (_exports, _decorators, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Controller.extend(dt7948.p({
    router: (0, _service.inject)(),
    menuExpanded: false,
    isIndex(currentRouteName) {
      return currentRouteName === 'knowledgeBase.index';
    },
    actions: {
      toggleMenu() {
        this.toggleProperty('menuExpanded');
      }
    }
  }, [["method", "isIndex", [(0, _decorators.default)('router.currentRouteName')]]]));
});