define("discourse/plugins/discourse-knowledge-base/discourse/lib/knowledge-base-utilities", ["exports", "@ember/object", "discourse/lib/text-direction", "discourse-common/lib/icon-library", "discourse/models/category"], function (_exports, _object, _textDirection, _iconLibrary, _category) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.knowledgeBaseCategoryLinkRenderer = knowledgeBaseCategoryLinkRenderer;
  let escapeExpression = Handlebars.Utils.escapeExpression;
  function categoryStripe(color, classes) {
    var style = color ? "style='background-color: #" + color + ";'" : "";
    return "<span class='" + classes + "' " + style + "></span>";
  }
  function knowledgeBaseCategoryLinkRenderer(category, opts) {
    // Same as core, apart from this
    if (category && category.knowledge_base) {
      opts.url = `/k/${category.slug}`;
    }
    //

    let descriptionText = (0, _object.get)(category, "description_text");
    let restricted = (0, _object.get)(category, "read_restricted");
    let url = opts.url ? opts.url : Discourse.getURL(`/c/${_category.default.slugFor(category)}/${(0, _object.get)(category, "id")}`);
    let href = opts.link === false ? "" : url;
    let tagName = opts.link === false || opts.link === "false" ? "span" : "a";
    let extraClasses = opts.extraClasses ? " " + opts.extraClasses : "";
    let color = (0, _object.get)(category, "color");
    let html = "";
    let parentCat = null;
    let categoryDir = "";
    if (!opts.hideParent) {
      parentCat = _category.default.findById((0, _object.get)(category, "parent_category_id"));
    }
    const categoryStyle = opts.categoryStyle || Discourse.SiteSettings.category_style;
    if (categoryStyle !== "none") {
      if (parentCat && parentCat !== category) {
        html += categoryStripe((0, _object.get)(parentCat, "color"), "badge-category-parent-bg");
      }
      html += categoryStripe(color, "badge-category-bg");
    }
    let classNames = "badge-category clear-badge";
    if (restricted) {
      classNames += " restricted";
    }
    let style = "";
    if (categoryStyle === "box") {
      style = `style="color: #${(0, _object.get)(category, "text_color")};"`;
    }
    html += `<span ${style} ` + 'data-drop-close="true" class="' + classNames + '"' + (descriptionText ? 'title="' + descriptionText + '" ' : "") + ">";
    let categoryName = escapeExpression((0, _object.get)(category, "name"));
    if (Discourse.SiteSettings.support_mixed_text_direction) {
      categoryDir = (0, _textDirection.isRTL)(categoryName) ? 'dir="rtl"' : 'dir="ltr"';
    }
    if (restricted) {
      html += `${(0, _iconLibrary.iconHTML)("lock")}<span class="category-name" ${categoryDir}>${categoryName}</span>`;
    } else {
      html += `<span class="category-name" ${categoryDir}>${categoryName}</span>`;
    }
    html += "</span>";
    if (href) {
      href = ` href="${href}" `;
    }
    extraClasses = categoryStyle ? categoryStyle + extraClasses : extraClasses;
    return `<${tagName} class="badge-wrapper ${extraClasses}" ${href}>${html}</${tagName}>`;
  }
});