define("discourse/plugins/discourse-knowledge-base/discourse/initializers/discourse-knowledge-base", ["exports", "discourse/lib/plugin-api", "discourse/plugins/discourse-knowledge-base/discourse/lib/knowledge-base-utilities"], function (_exports, _pluginApi, _knowledgeBaseUtilities) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "init-knowledge-base",
    initialize(container) {
      const siteSettings = container.lookup('site-settings:main');
      if (!siteSettings.knowledge_base_enabled) return;
      (0, _pluginApi.withPluginApi)('0.8.37', api => {
        if (siteSettings.knowledge_base_change_category_badge_link) {
          api.replaceCategoryLinkRenderer(_knowledgeBaseUtilities.knowledgeBaseCategoryLinkRenderer);
        }
        if (siteSettings.knowledge_base_category_list) {
          api.addNavigationBarItem({
            name: "kb",
            customHref: (category, args, router) => {
              return `${category.url}/l/kb`;
            },
            customFilter: (category, args, router) => {
              return category && category.knowledge_base;
            }
          });
        }
      });
    }
  };
});