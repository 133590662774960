define("discourse/plugins/discourse-knowledge-base/discourse/controllers/knowledge-base-section", ["exports", "discourse/lib/url"], function (_exports, _url) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Controller.extend({
    actions: {
      goToTopic() {
        const topic = this.get('topic');
        _url.default.routeTo(`/t/${topic.slug}/${topic.id}`);
      },
      print() {
        const category = this.get('category');
        window.location = `/k/${category.slug}/print`;
      }
    }
  });
});