define("discourse/plugins/discourse-knowledge-base/discourse/templates/connectors/category-custom-settings/knowledge-base-settings", ["exports", "ember-this-fallback/deprecations-helper", "@ember/template-factory"], function (_exports, _deprecationsHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <h3>{{i18n 'knowledge_base.category.title'}}</h3>
  
  <section class="field">
    {{input type="checkbox" checked=category.custom_fields.knowledge_base}}
    <span>{{i18n 'knowledge_base.category.enable'}}</span>
  </section>
  
  */
  {
    "id": "adBjuDhu",
    "block": "[[[10,\"h3\"],[12],[1,[28,[35,0],[\"knowledge_base.category.title\"],null]],[13],[1,\"\\n\\n\"],[10,\"section\"],[14,0,\"field\"],[12],[1,\"\\n  \"],[1,[28,[35,1],null,[[\"type\",\"checked\"],[\"checkbox\",[30,0,[\"category\",\"custom_fields\",\"knowledge_base\"]]]]]],[1,\"\\n  \"],[10,1],[12],[1,[28,[35,0],[\"knowledge_base.category.enable\"],null]],[13],[1,\"\\n\"],[13],[1,\"\\n\"],[1,[28,[32,0],[\"[[\\\"The `category` property path was used in the `discourse/plugins/discourse-knowledge-base/discourse/templates/connectors/category-custom-settings/knowledge-base-settings.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.category}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]]\"],null]]],[],false,[\"i18n\",\"input\"]]",
    "moduleName": "discourse/plugins/discourse-knowledge-base/discourse/templates/connectors/category-custom-settings/knowledge-base-settings.hbs",
    "scope": () => [_deprecationsHelper.default],
    "isStrictMode": false
  });
});