define("discourse/plugins/discourse-knowledge-base/discourse/routes/knowledge-base", ["exports", "discourse/lib/ajax", "discourse/lib/text", "discourse/lib/url"], function (_exports, _ajax, _text, _url) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend({
    model() {
      return (0, _ajax.ajax)('/k');
    },
    setupController(controller, model) {
      controller.setProperties({
        topicsList: model
      });
      (0, _text.cook)(I18n.t('knowledge_base.description')).then(cooked => {
        controller.set('description', cooked);
      });
    }
  });
});