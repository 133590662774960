define("discourse/plugins/discourse-knowledge-base/discourse/templates/knowledge-base-section", ["exports", "ember-this-fallback/deprecations-helper", "@ember/template-factory"], function (_exports, _deprecationsHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="knowledge-base-title">
    {{d-button label="topic.print.title"
      title="topic.print.help"
      icon="print"
      action="print"
      class="print"}}
    <h2>{{topic.title}}</h2>
    <a class="go-to-topic" {{action 'goToTopic'}}>
      {{d-icon 'sign-out'}}
      <span>{{i18n 'knowledge_base.go_to_topic'}}</span>
    </a>
  </div>
  <div class="knowledge-base-post">
    {{{post.cooked}}}
  </div>
  
  */
  {
    "id": "XynrDXla",
    "block": "[[[10,0],[14,0,\"knowledge-base-title\"],[12],[1,\"\\n  \"],[1,[28,[35,0],null,[[\"label\",\"title\",\"icon\",\"action\",\"class\"],[\"topic.print.title\",\"topic.print.help\",\"print\",\"print\",\"print\"]]]],[1,\"\\n  \"],[10,\"h2\"],[12],[1,[30,0,[\"topic\",\"title\"]]],[13],[1,\"\\n  \"],[11,3],[24,0,\"go-to-topic\"],[4,[38,1],[[30,0],\"goToTopic\"],null],[12],[1,\"\\n    \"],[1,[28,[35,2],[\"sign-out\"],null]],[1,\"\\n    \"],[10,1],[12],[1,[28,[35,3],[\"knowledge_base.go_to_topic\"],null]],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"],[10,0],[14,0,\"knowledge-base-post\"],[12],[1,\"\\n  \"],[2,[30,0,[\"post\",\"cooked\"]]],[1,\"\\n\"],[13],[1,\"\\n\"],[1,[28,[32,0],[\"[[\\\"The `topic` property path was used in the `discourse/plugins/discourse-knowledge-base/discourse/templates/knowledge-base-section.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.topic}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}],[\\\"The `post` property path was used in the `discourse/plugins/discourse-knowledge-base/discourse/templates/knowledge-base-section.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.post}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]]\"],null]]],[],false,[\"d-button\",\"action\",\"d-icon\",\"i18n\"]]",
    "moduleName": "discourse/plugins/discourse-knowledge-base/discourse/templates/knowledge-base-section.hbs",
    "scope": () => [_deprecationsHelper.default],
    "isStrictMode": false
  });
});