define("discourse/plugins/discourse-knowledge-base/discourse/controllers/knowledge-base-topic", ["exports", "discourse/lib/url", "discourse-common/utils/decorators"], function (_exports, _url, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Controller.extend(dt7948.p({
    printLabel() {
      return this.site.mobileView ? '' : I18n.t("topic.print.title");
    },
    actions: {
      goToTopic() {
        const topic = this.get('topic');
        _url.default.routeTo(`/t/${topic.slug}/${topic.id}`);
      },
      print() {
        const topic = this.get('topic');
        const category = this.get('category');
        window.location = `/k/${category.slug}/${topic.slug}/${topic.id}/print`;
      }
    }
  }, [["method", "printLabel", [_decorators.default]]]));
});